import React from 'react';

const Summary = ({ data, note }) => (
  <section className="py-5 border-b border-neutral-300 lg:flex items-center">
    <div className="my-5">
      <img
        className="rounded-full mx-auto w-32 lg:w-full no-print"
        src="./profile.jpg"
        alt="profile"
      />
    </div>
    <p className="text-left tracking-wide leading-relaxed lg:text-left lg:mx-8 lg:text-lg">
      {data}
      <p className="summary-note" color-primary>{note}</p>
    </p>

  </section>
);

export default Summary;
