import React from 'react';
import {Education, Experience, Footer, Header, List, Talks, SEO, Skills, Summary, Articles} from '../components';
import resume from '../../data/profile';
import '../main.css';

const Home = () => (
        <main className="antialiased text-neutral-900 bg-neutral-100 min-h-screen sm:p-5">
            <SEO />
            <div className="container mx-auto shadow bg-white py-5 px-10">
                <Header
                    contacts={resume.contact}
                    name={resume.fullname}
                    role={resume.role}
                />
                <Summary data={resume.summary} note={resume.note}/>
                <div className="border-b border-neutral-300 pb-2 my-5 lg:flex">
                    <div className="lg:w-2/3 lg:pr-8">
                        {resume.experience && <Experience data={resume.experience}/>}
                        {resume.talks && <Talks data={resume.talks}/>}
                        {resume.articles && <Articles data={resume.articles}/>}
                    </div>
                    <div className="lg:w-1/3 lg:pl-8 lg:border-l lg:border-neutral-300 ">
                        {resume.education && <Education data={resume.education}/>}
                        {resume.skills && <Skills data={resume.skills}/>}
                        {resume.sidebar &&
                        resume.sidebar.map(item => (
                            <List key={`${item.title}-side`} data={item}/>
                        ))}
                    </div>
                </div>
                <Footer social={resume.social}/>
            </div>
        </main>
);

export default Home;
