import React from 'react';

const Education = ({ data }) => (
  <section className="mb-5">
    <h2 className="section-header">Education</h2>
    {data &&
      data.map(item => (
        <div className="my-5" key={item.degree}>
          <h3 className="item-header text-lg">{item.degree}</h3>
          <h4 className="item-sub">{item.institution}</h4>
          <p className="text-sm text-neutral-500 font-light">
            {item.start} - {item.end}
          </p>
        </div>
      ))}
  </section>
);

export default Education;
