import React from 'react';

const Experience = ({data}) => (
    <section>
        <h2 className="section-header">Experience</h2>
        {data &&
        data.map((item, i) => (
            <article className="my-5" key={`${item.company}-${i}`}>
                <h3 className="item-header">{item.role}</h3>
                <h4 className="item-sub">
                    <a title={item.company}
                       href={item.companyLink}> {item.company} </a> | {item.start} - {item.end || 'PRESENT'}
                </h4>
                <p className="desc py-6">{item.description}</p>
                {item.tags && item.tags.map(tag => (
                    <span key={tag}>
              <span key={tag} _tag className="tag">
                {tag}
              </span>
                 </span>
                ))}
            </article>
        ))}
    </section>
);

export default Experience;
