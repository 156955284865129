import React from 'react';

const Talks = ({ data }) => (
  <section>
    <h2 className="section-header">Courses, Workshops and Talks</h2>
    {data.map(item => (
      <article className="my-5" key={item.name}>
        <h3 className="item-header">{item.name}</h3>
        <h4 className="item-sub">{item.description}</h4>
      </article>
    ))}
  </section>
);

export default Talks;
