import React from 'react';

const Articles = ({ data }) => (
  <section>
    <h2 className="section-header">Papers</h2>
    {data.map(item => (
      <article className="my-5" key={item.title}>
        <h3 className="item-header">{item.title}</h3>
        <h4 className="item-sub">{item.venue}</h4>
      </article>
    ))}
  </section>
);

export default Articles;
