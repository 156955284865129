/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';
import ReactDOMServer from 'react-dom/server';

const SEO = ({description, lang, meta, title}) => {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            profile
            social { twitter}
            siteUrl
          }
        }
      }
    `
    );
    const metaDescription = description || site.siteMetadata.description;
    return (
        <Helmet defer={false}
            htmlAttributes={{
                lang,
            }}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:site_name`,
                    content: title,
                },
                {
                    name: `og:image`,
                    content: site.siteMetadata.profile,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:image`,
                    content: site.siteMetadata.profile,
                },
                {
                    name: `twitter:site`,
                    content: site.siteMetadata.social.twitter,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:url`,
                    content: site.siteMetadata.siteUrl,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]}
        >
    <title>{title}</title>
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO;
